import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Message } from '../../shared/Message';
import { EmptyTab } from '../shared/components/styled/EmptyTab';
import { ANTD_GRAY } from '../shared/constants';
import AddRelatedNodeModal from '../glossaryTerm/profile/AddRelatedTermsModal';
import RelatedNode from '../glossaryTerm/profile/RelatedTerm';
import { EntityType } from '../../../types.generated';

export enum RelatedNodeTypes {
    typeOfRelations = 'Type Of',
    isTypeOf = 'Has Type',
}

export type Props = {
    glossaryRelatedNodeType: string;
    glossaryRelatedNodeResult: Array<any>;
};

const ListContainer = styled.div`
    width: 100%;
`;

const TitleContainer = styled.div`
    align-items: center;
    border-bottom: solid 1px ${ANTD_GRAY[4]};
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 30px;
`;

const messageStyle = { marginTop: '10%' };

export default function GlossaryRelatedTermsResult({ glossaryRelatedNodeType, glossaryRelatedNodeResult }: Props) {
    const [isShowingAddModal, setIsShowingAddModal] = useState(false);
    const glossaryRelatedNodeUrns: Array<string> = [];

    glossaryRelatedNodeResult.forEach((item: any) => {
        glossaryRelatedNodeUrns.push(item?.entity?.urn);
    });

    const contentLoading = false;

    const canEditRelatedTerms = glossaryRelatedNodeType === RelatedNodeTypes.typeOfRelations;
    return (
        <>
            {contentLoading ? (
                <Message type="loading" content="Loading..." style={messageStyle} />
            ) : (
                <ListContainer>
                    <TitleContainer>
                        <Typography.Title style={{ margin: '0' }} level={3}>
                            {glossaryRelatedNodeType}
                        </Typography.Title>
                        {canEditRelatedTerms && (
                            <Button type="text" onClick={() => setIsShowingAddModal(true)} aria-label="Add Nodes">
                                <PlusOutlined /> Add Term Groups
                            </Button>
                        )}
                    </TitleContainer>
                    {glossaryRelatedNodeUrns.map((urn) => (
                        <RelatedNode
                            key={urn}
                            urn={urn}
                            isEditable={canEditRelatedTerms}
                            entityType={EntityType.GlossaryNode}
                            relatedEntityType={glossaryRelatedNodeType}
                        />
                    ))}
                    {glossaryRelatedNodeUrns.length === 0 && (
                        <EmptyTab tab={`node ${glossaryRelatedNodeType.toLocaleLowerCase()}`} />
                    )}
                </ListContainer>
            )}
            {isShowingAddModal && (
                <AddRelatedNodeModal
                    isTypeOfBy
                    entityType={EntityType.GlossaryNode}
                    onClose={() => setIsShowingAddModal(false)}
                />
            )}
        </>
    );
}
