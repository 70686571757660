import { message, Modal } from 'antd';
import { useEntityData, useRefetch } from '../../shared/EntityContext';
import { useRemoveRelatedTermsMutation } from '../../../../graphql/glossaryTerm.generated';
import { useRemoveTypeOfRelationsMutation } from '../../../../graphql/mutations.generated';
import { TermRelationshipType } from '../../../../types.generated';

function useRemoveRelatedTerms(
    termUrn: string,
    relationshipType: TermRelationshipType | undefined,
    displayName: string,
    relatedTermType,
    entityName: string,
) {
    const { urn } = useEntityData();

    const isTypeOfBy = relatedTermType === 'Type Of';

    const refetch = useRefetch();

    const [removeTypeOfRelations] = useRemoveTypeOfRelationsMutation();
    const [removeRelatedTerms] = useRemoveRelatedTermsMutation();

    function handleRemoveRelatedTerms() {
        const removeRelation: any = isTypeOfBy ? removeTypeOfRelations : removeRelatedTerms;

        const variables = isTypeOfBy
            ? { input: { urn, nodeUrns: [termUrn] } }
            : { input: { urn, termUrns: [termUrn], relationshipType } };

        removeRelation({ variables })
            .catch((e) => {
                message.destroy();
                message.error({ content: `Failed to remove: \n ${e.message || ''}`, duration: 3 });
            })
            .finally(() => {
                message.loading({
                    content: 'Removing...',
                    duration: 2,
                });
                setTimeout(() => {
                    refetch();
                    message.success({
                        content: `Removed ${entityName} Successfully!`,
                        duration: 2,
                    });
                }, 2000);
            });
    }

    function onRemove() {
        Modal.confirm({
            title: `Remove ${displayName}`,
            content: `Are you sure you want to remove this ${entityName}?`,
            onOk() {
                handleRemoveRelatedTerms();
            },
            onCancel() {},
            okText: 'Yes',
            maskClosable: true,
            centered: true,
            closable: true,
        });
    }

    return { onRemove };
}

export default useRemoveRelatedTerms;
