import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Menu } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';
import { useGetGlossaryTermQuery } from '../../../../graphql/glossaryTerm.generated';
import { useGetGlossaryNodeQuery } from '../../../../graphql/glossaryNode.generated';

import { EntityType, TermRelationshipType } from '../../../../types.generated';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { PreviewType } from '../../Entity';
import useRemoveRelatedTerms from './useRemoveRelatedTerms';

const ListItem = styled.div`
    margin: 0 20px;
`;

const Profile = styled.div`
    display: felx;
    marging-bottom: 20px;
`;

const MenuIcon = styled(MoreOutlined)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 32px;
    margin-left: -10px;
`;

const MenuItem = styled.div`
    padding: 0 4px;
    color: #262626;
`;

interface Props {
    urn: string;
    entityType: EntityType;
    relationshipType?: TermRelationshipType;
    relatedEntityType: any;
    isEditable: boolean;
}

const getEntityConfig = (entityType) => {
    const defaultConfig = {
        useGetGlossaryQuery: useGetGlossaryTermQuery,
        dataKey: 'glossaryTerm',
        entityName: 'Term',
    };

    // Choose the correct entity type based on the entity type
    switch (entityType) {
        case EntityType.GlossaryTerm:
            return defaultConfig;

        case EntityType.GlossaryNode:
            return {
                useGetGlossaryQuery: useGetGlossaryNodeQuery,
                dataKey: 'glossaryNode',
                entityName: 'Term Group',
            };

        default:
            return defaultConfig;
    }
};

function LinkedEntity(props: Props) {
    const { urn, relationshipType, isEditable, relatedEntityType, entityType } = props;
    const { useGetGlossaryQuery, dataKey, entityName } = getEntityConfig(entityType);

    const { data, loading } = useGetGlossaryQuery({ variables: { urn } });
    const entityRegistry = useEntityRegistry();

    let displayName = '';

    if (data) {
        displayName = entityRegistry.getDisplayName(entityType, data[dataKey]);
    }

    const { onRemove } = useRemoveRelatedTerms(urn, relationshipType, displayName, relatedEntityType, entityName);

    if (loading) return null;

    return (
        <ListItem>
            <Profile>
                {entityRegistry.renderPreview(entityType, PreviewType.PREVIEW, data?.[dataKey])}
                {isEditable && (
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="0" aria-label={`Remove ${entityName}`}>
                                    <MenuItem onClick={onRemove}>
                                        <DeleteOutlined /> &nbsp; {`Remove ${entityName}`}
                                    </MenuItem>
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <MenuIcon tabIndex={0} />
                    </Dropdown>
                )}
            </Profile>
            <Divider style={{ margin: '20px 0' }} />
        </ListItem>
    );
}

export default LinkedEntity;
