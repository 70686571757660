import React from 'react';
import { Button, Row } from 'antd';
import { FileFilled, ArrowRightOutlined } from '@ant-design/icons';

interface Props {
    title?: string;
    subtitle?: string;
    selectedFile: string;
    onRetry: (e) => void;
    setIsErrorLogModalVisible: (isVisible: boolean) => void;
}

const VerificationStatus = ({
    title = 'Verification Failed',
    subtitle = 'There was an error while uploading the following file.',
    selectedFile,
    onRetry,
    setIsErrorLogModalVisible,
}: Props) => {
    return (
        <>
            <div className="f-container-title f-container-title-failed">{title}</div>
            <div className="f-container-subtitle mt-2">{subtitle}</div>
            <div className="my-2">
                <FileFilled style={{ color: '#D96758' }} className="mr-2" />
                {selectedFile || 'No file selected'}
            </div>
            <Row justify="center" align="middle">
                <Button type="primary" className="mr-2" onClick={onRetry}>
                    Retry
                </Button>
                <Button type="default" onClick={() => setIsErrorLogModalVisible(true)}>
                    View Error Log <ArrowRightOutlined />
                </Button>
            </Row>
        </>
    );
};

export default VerificationStatus;
