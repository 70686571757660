import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import { ANTD_GRAY, PROPERTY_MAPPING } from '../../../constants';
import { StyledTable } from '../../../components/styled/StyledTable';
import { useEntityData } from '../../../EntityContext';

import { useGetPrivaciTabPropertiesQuery } from '../../../../../../graphql/dataset.generated';

const NameText = styled(Typography.Text)`
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    color: ${ANTD_GRAY[9]};
`;

const ValueText = styled(Typography.Text)`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    color: ${ANTD_GRAY[8]};
`;

export const AssetMapPropertiesTab = () => {
    const { entityData }: any = useEntityData();

    const shouldFetch = !!entityData?.urn; // Condition to check if the query should be called

    const { data }: any = useGetPrivaciTabPropertiesQuery({
        variables: {
            input: shouldFetch ? entityData?.urn : '',
        },
        skip: !shouldFetch,
    });

    const privaciTabProperties = data?.getPrivaciTabProperties?.values || [];
    const assetLink = data?.getPrivaciTabProperties?.assetLink || '';

    // This help us to render html w.r.t the following condition
    const isSourceTypeLink = (property) => {
        return !!PROPERTY_MAPPING[property?.key];
    };

    // checks if the url is valid or not
    function isValidUrl(string) {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    const getCustomProperties = () => {
        const assetRouteProperty = { key: 'data_asset_map', value: assetLink };
        const assetMapProperties = privaciTabProperties.map((property) => {
            return {
                key: property.name,
                value: isValidUrl(property.value) || property.value,
            };
        });

        return [assetRouteProperty, ...assetMapProperties];
    };

    const displayLinkName = (sourceUrl, sourceKey) => {
        const key = sourceKey.replaceAll(' ', '-');
        const assetMap = PROPERTY_MAPPING[key];

        return sourceUrl ? (
            <a href={sourceUrl} target="_blank" rel="noreferrer">
                {assetMap}
            </a>
        ) : (
            assetMap
        );
    };

    const propertyTableColumns = [
        {
            width: 410,
            title: 'Name',
            dataIndex: 'key',
            sorter: (a, b) => a?.key.localeCompare(b?.key || '') || 0,
            render: (name: string) => <NameText className="f-text-medium-content f-text-single-line">{name}</NameText>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render: (value: string, property) => (
                <ValueText className="f-text-medium-content f-text-single-line">
                    {isSourceTypeLink(property) ? displayLinkName(value, property.key) : value}
                </ValueText>
            ),
        },
    ];

    return (
        <StyledTable
            pagination={false}
            // typescript is complaining that default sort order is not a valid column field- overriding this here
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={propertyTableColumns}
            dataSource={getCustomProperties()}
        />
    );
};
