import React, { useState } from 'react';
import { Typography, Button, message } from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { ANTD_GRAY, PROPERTY_MAPPING } from '../../constants';
import { StyledTable } from '../../components/styled/StyledTable';
import { useEntityData, useRefetch } from '../../EntityContext';
import { AddOrEditProperty, OperationType, DATA_PRODUCT, GLOSSARY_TERM, DOMAIN } from './AddOrEditProperty';
import { useDeleteGlossaryPropertyMutation } from '../../../../../graphql/glossary.generated';
import { useDeleteEntityPropertyMutation } from '../../../../../graphql/dataset.generated';
import { useDeleteDataProductPropertyMutation } from '../../../../../graphql/dataProduct.generated';
import { useDeleteDomainPropertyMutation } from '../../../../../graphql/domain.generated';

import { showDeleteModal } from '../../utils';

const NameText = styled(Typography.Text)`
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    color: ${ANTD_GRAY[9]};
`;

const ValueText = styled(Typography.Text)`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    color: ${ANTD_GRAY[8]};
`;

const AddPropertyButton = styled(Button)`
    width: 100%;
    display: flex;
    justify-content: right;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: right;
`;

const DEFINITION = 'Definition';

export const PropertiesTab = () => {
    const { entityData }: any = useEntityData();
    const refetch = useRefetch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [operationType, setOperationType] = useState(OperationType.ADD);
    const [editModalData, setEditModalData] = useState({});

    const [deleteGlossaryPropertyMutation] = useDeleteGlossaryPropertyMutation();
    const [deleteEntityProperty] = useDeleteEntityPropertyMutation();
    const [deleteDomainPropertyMutation] = useDeleteDomainPropertyMutation();
    const [deleteDataProductPropertyMutation] = useDeleteDataProductPropertyMutation();

    const canAddProperty = ['DATASET', 'CONTAINER', 'DATA_PRODUCT', 'DOMAIN'].includes(entityData?.type);

    const isGlossary = entityData?.type === GLOSSARY_TERM;
    const isCatalogEntity = [DATA_PRODUCT, DOMAIN].includes(entityData?.type);

    const isEditable = isGlossary ? entityData?.properties?.isEditable !== false : isCatalogEntity;

    // This help us to render html w.r.t the following condition
    const isSourceTypeLink = (property) => {
        return !!PROPERTY_MAPPING[property?.key];
    };

    // checks if the url is valid or not
    function isValidUrl(string) {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    const getCustomProperties = (properties) => {
        return properties?.filter((p) => {
            if (p.key === DEFINITION) {
                return isValidUrl(p.value);
            }
            return true;
        });
    };

    const displayLinkName = (sourceUrl, sourceKey) => {
        const key = sourceKey.replaceAll(' ', '-');
        const dsName = PROPERTY_MAPPING[key];

        return sourceUrl ? (
            <a href={sourceUrl} target="_blank" rel="noreferrer">
                {dsName}
            </a>
        ) : (
            dsName
        );
    };

    // Opens the add or edit modal based on OperationType.
    const openAddOrEditModal = (type, record) => {
        setIsModalVisible(true);
        setOperationType(type);

        if (type === OperationType.EDIT) {
            setEditModalData(record);
        }
    };

    const getDeleteMutationFunc = (type) => {
        switch (type) {
            case DATA_PRODUCT:
                return deleteDataProductPropertyMutation;
            case GLOSSARY_TERM:
                return deleteGlossaryPropertyMutation;
            case DOMAIN:
                return deleteDomainPropertyMutation;
            default:
                return deleteEntityProperty;
        }
    };

    // Deletes a glossary property.
    const deleteGlossaryProperty = (key) => {
        // Checks if the entity is a glossary term. If it is, it will delete the glossary property.
        const deleteMutation = getDeleteMutationFunc(entityData?.type);

        deleteMutation({
            variables: {
                input: {
                    urn: entityData?.urn || '',
                    key,
                },
            },
        })
            .then(({ errors }: any) => {
                if (!errors) {
                    message.success('Property deleted successfully.');
                    refetch?.();
                }
            })
            .catch((e) => {
                message.destroy();
                message.error({
                    content: `Failed to delete the property: \n ${e.message || ''}`,
                    duration: 3,
                });
            });
    };

    const onConfirmDelete = (record) => {
        const content = `Are you sure you want to delete “${record.key}”? Please note that this action cannot be undone.`;
        showDeleteModal('Property', () => deleteGlossaryProperty(record.key), content);
    };

    const propertyTableColumns = [
        {
            width: 210,
            title: 'Name',
            dataIndex: 'key',
            ariaLabel: 'Name',
            sorter: (a, b) => a?.key.localeCompare(b?.key || '') || 0,
            defaultSortOrder: 'ascend',
            render: (name: string) => <NameText className="f-text-medium-content f-text-single-line">{name}</NameText>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render: (value: string, property) => (
                <ValueText
                    className="f-text-medium-content f-truncate-two-line"
                    title={isSourceTypeLink(property) ? displayLinkName(value, property.key) : value}
                >
                    {isSourceTypeLink(property) ? displayLinkName(value, property.key) : value}
                </ValueText>
            ),
        },
        {
            title:
                isEditable || canAddProperty ? (
                    <AddPropertyButton
                        type="text"
                        onClick={() => openAddOrEditModal(OperationType.ADD, '')}
                        aria-label="Add New Property"
                    >
                        <PlusOutlined />
                        Add New Property
                    </AddPropertyButton>
                ) : (
                    ''
                ),
            render: (record: any) =>
                isEditable || record?.isEditable ? (
                    <ActionButtonContainer>
                        <Button className="mr-2" onClick={() => openAddOrEditModal(OperationType.EDIT, record)}>
                            Edit
                        </Button>
                        <Button
                            onClick={() => onConfirmDelete(record)}
                            data-testid="delete-button"
                            type="text"
                            shape="circle"
                            danger
                        >
                            <DeleteFilled />
                        </Button>
                    </ActionButtonContainer>
                ) : (
                    ''
                ),
        },
    ];

    return (
        <>
            <StyledTable
                pagination={false}
                // typescript is complaining that default sort order is not a valid column field- overriding this here
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={propertyTableColumns}
                dataSource={getCustomProperties(entityData?.customProperties) || undefined}
            />
            <AddOrEditProperty
                urn={entityData?.urn}
                type={entityData?.type}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                editModalData={editModalData || ''}
                operationType={operationType}
                refetch={refetch}
            />
        </>
    );
};
