import { DeleteOutlined, EditOutlined, SyncOutlined, CommentOutlined } from '@ant-design/icons';
import { Button, Dropdown, message } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { UpdateGovernanceStatusModal } from '../../shared/EntityDropdown/UpdateGovernanceStatusModal';
import { MenuIcon } from '../../shared/EntityDropdown/EntityDropdown';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { CLIENT_AUTH_COOKIE } from '../../../../conf/Global';
import EditDataProductModal from './EditDataProductModal';
import { showDeleteModal } from '../../shared/utils';
import { PreviewType } from '../../Entity';

import { useDeleteDataProductMutation } from '../../../../graphql/dataProduct.generated';
import { useCheckModuleEnabledQuery } from '../../../../graphql/app.generated';
import { useGetCommentsQuery } from '../../../../graphql/user.generated';
import { DataProduct, EntityType } from '../../../../types.generated';

const ResultWrapper = styled.div`
    background-color: white;
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto 8px auto;
    padding: 8px 16px;
    display: flex;
    width: 100%;
`;

const StyledButton = styled(Button)`
    background: transparent !important;
    box-shadow: none;
    outline: none;
    border: none;
    height: 18px;
    width: 18px;
    padding: 0;

    svg {
        height: 14px;
        width: 14px;
    }
`;

const ButtonsWrapper = styled.div`
    margin-left: 16px;
    display: flex;
`;

const StyledMenuIcon = styled(MenuIcon)`
    margin-left: 8px;
    height: 18px;
    width: 18px;
`;

const PreviewWrapper = styled.div`
    max-width: 94%;
    flex: 1;
`;

const MenuItem = styled.div``;

interface Props {
    key: string;
    dataProduct: DataProduct;
    refetch: () => void;
    onUpdateDataProduct: (dataProduct: DataProduct) => void;
    setDeletedDataProductUrns: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function DataProductResult({
    dataProduct,
    onUpdateDataProduct,
    setDeletedDataProductUrns,
    key: urn,
    refetch,
}: Props) {
    const entityRegistry = useEntityRegistry();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isGovernanceModalVisible, setIsGovernanceModalVisible] = useState(false);
    const [deleteDataProductMutation] = useDeleteDataProductMutation();

    const { data } = useGetCommentsQuery({
        variables: { input: { entity: urn } },
        skip: !urn, // Skip the query if urn is null or undefined
    });
    const { data: moduleInfo } = useCheckModuleEnabledQuery({ variables: {} });

    const isMessageCenterEnabled = moduleInfo?.checkModuleEnabled?.isEnabled;

    const actorUrn = Cookies.get(CLIENT_AUTH_COOKIE) || '';

    // This method checks if current user has commented in the dataset or not
    const hasUserCommented = () => {
        return data?.getComments?.users?.includes(actorUrn);
    };

    const handleButtonClick = () => {
        window.parent.postMessage({ action: 'openNavigationModal', urn, isTempChannel: !hasUserCommented() }, '*');
    };

    function deleteDataProduct() {
        deleteDataProductMutation({ variables: { urn: dataProduct.urn } })
            .then(() => {
                message.success('Deleted Data Product');
                setDeletedDataProductUrns((currentUrns) => [...currentUrns, dataProduct.urn]);
            })
            .catch(() => {
                message.destroy();
                message.error({ content: 'Failed to delete Data Product. An unexpected error occurred' });
            });
    }

    function onRemove() {
        showDeleteModal(entityRegistry.getDisplayName(EntityType.DataProduct, dataProduct), deleteDataProduct);
    }

    const items = [
        {
            key: '0',
            label: (
                <MenuItem aria-label="Delete" onClick={onRemove}>
                    <DeleteOutlined /> &nbsp;Delete
                </MenuItem>
            ),
        },
        {
            key: '1',
            label: (
                <MenuItem aria-label="Update Governance Status" onClick={() => setIsGovernanceModalVisible(true)}>
                    <SyncOutlined /> &nbsp;Update Governance Status
                </MenuItem>
            ),
        },
        ...(isMessageCenterEnabled
            ? [
                  {
                      key: '2',
                      label: (
                          <MenuItem aria-label="Comment" onClick={handleButtonClick}>
                              <CommentOutlined /> &nbsp; Comment
                          </MenuItem>
                      ),
                  },
              ]
            : []),
    ];

    return (
        <>
            <ResultWrapper>
                <PreviewWrapper>
                    {entityRegistry.renderPreview(EntityType.DataProduct, PreviewType.SEARCH, dataProduct)}
                </PreviewWrapper>
                <ButtonsWrapper>
                    <StyledButton icon={<EditOutlined />} onClick={() => setIsEditModalVisible(true)} />
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <StyledMenuIcon />
                    </Dropdown>
                </ButtonsWrapper>
            </ResultWrapper>
            {isEditModalVisible && (
                <EditDataProductModal
                    dataProduct={dataProduct}
                    onClose={() => setIsEditModalVisible(false)}
                    onUpdateDataProduct={onUpdateDataProduct}
                />
            )}

            {isGovernanceModalVisible && (
                <UpdateGovernanceStatusModal
                    entityData={dataProduct}
                    refetch={refetch}
                    onClose={() => setIsGovernanceModalVisible(false)}
                />
            )}
        </>
    );
}
