/* eslint-disable */
import * as Types from '../types.generated';

import {
    ParentDomainsFieldsFragment,
    OwnershipFieldsFragment,
    DomainEntitiesFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    ParentDomainsFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    DomainEntitiesFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetDomainQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDomainQuery = { __typename?: 'Query' } & {
    domain?: Types.Maybe<
        { __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'id' | 'type'> & {
                properties?: Types.Maybe<
                    { __typename?: 'DomainProperties' } & Pick<Types.DomainProperties, 'name' | 'description'> & {
                            customProperties?: Types.Maybe<
                                Array<
                                    { __typename?: 'CustomPropertiesEntry' } & Pick<
                                        Types.CustomPropertiesEntry,
                                        'key' | 'value'
                                    >
                                >
                            >;
                        }
                >;
                parentDomains?: Types.Maybe<{ __typename?: 'ParentDomainsResult' } & ParentDomainsFieldsFragment>;
                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                institutionalMemory?: Types.Maybe<
                    { __typename?: 'InstitutionalMemory' } & {
                        elements: Array<
                            { __typename?: 'InstitutionalMemoryMetadata' } & Pick<
                                Types.InstitutionalMemoryMetadata,
                                'url' | 'description'
                            > & {
                                    author: { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'>;
                                    created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'actor' | 'time'>;
                                }
                        >;
                    }
                >;
                children?: Types.Maybe<
                    { __typename?: 'EntityRelationshipsResult' } & Pick<Types.EntityRelationshipsResult, 'total'>
                >;
            }
    >;
};

export type ListDomainsQueryVariables = Types.Exact<{
    input: Types.ListDomainsInput;
}>;

export type ListDomainsQuery = { __typename?: 'Query' } & {
    listDomains?: Types.Maybe<
        { __typename?: 'ListDomainsResult' } & Pick<Types.ListDomainsResult, 'start' | 'count' | 'total'> & {
                domains: Array<
                    { __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'id' | 'type'> & {
                            properties?: Types.Maybe<
                                { __typename?: 'DomainProperties' } & Pick<
                                    Types.DomainProperties,
                                    'name' | 'description'
                                >
                            >;
                            parentDomains?: Types.Maybe<
                                { __typename?: 'ParentDomainsResult' } & ParentDomainsFieldsFragment
                            >;
                            ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                        } & DomainEntitiesFieldsFragment
                >;
            }
    >;
};

export type GetDomainChildrenCountQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDomainChildrenCountQuery = { __typename?: 'Query' } & {
    domain?: Types.Maybe<
        { __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'> & {
                children?: Types.Maybe<
                    { __typename?: 'EntityRelationshipsResult' } & Pick<Types.EntityRelationshipsResult, 'total'>
                >;
            }
    >;
};

export type CreateDomainMutationVariables = Types.Exact<{
    input: Types.CreateDomainInput;
}>;

export type CreateDomainMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'createDomain'>;

export type MoveDomainMutationVariables = Types.Exact<{
    input: Types.MoveDomainInput;
}>;

export type MoveDomainMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'moveDomain'>;

export type DeleteDomainMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type DeleteDomainMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'deleteDomain'>;

export type UpdateDomainPropertyMutationVariables = Types.Exact<{
    input: Types.UpdateDomainPropertyInfo;
}>;

export type UpdateDomainPropertyMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'updateDomainProperty'>;

export type DeleteDomainPropertyMutationVariables = Types.Exact<{
    input: Types.DeleteDomainPropertyInfo;
}>;

export type DeleteDomainPropertyMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'deleteDomainProperty'>;

export const GetDomainDocument = gql`
    query getDomain($urn: String!) {
        domain(urn: $urn) {
            urn
            id
            type
            properties {
                name
                description
                customProperties {
                    key
                    value
                }
            }
            parentDomains {
                ...parentDomainsFields
            }
            ownership {
                ...ownershipFields
            }
            institutionalMemory {
                elements {
                    url
                    author {
                        urn
                        username
                    }
                    description
                    created {
                        actor
                        time
                    }
                }
            }
            children: relationships(input: { types: ["IsPartOf"], direction: INCOMING, start: 0, count: 0 }) {
                total
            }
        }
    }
    ${ParentDomainsFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
`;

/**
 * __useGetDomainQuery__
 *
 * To run a query within a React component, call `useGetDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDomainQuery(baseOptions: Apollo.QueryHookOptions<GetDomainQuery, GetDomainQueryVariables>) {
    return Apollo.useQuery<GetDomainQuery, GetDomainQueryVariables>(GetDomainDocument, baseOptions);
}
export function useGetDomainLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDomainQuery, GetDomainQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDomainQuery, GetDomainQueryVariables>(GetDomainDocument, baseOptions);
}
export type GetDomainQueryHookResult = ReturnType<typeof useGetDomainQuery>;
export type GetDomainLazyQueryHookResult = ReturnType<typeof useGetDomainLazyQuery>;
export type GetDomainQueryResult = Apollo.QueryResult<GetDomainQuery, GetDomainQueryVariables>;
export const ListDomainsDocument = gql`
    query listDomains($input: ListDomainsInput!) {
        listDomains(input: $input) {
            start
            count
            total
            domains {
                urn
                id
                type
                properties {
                    name
                    description
                }
                parentDomains {
                    ...parentDomainsFields
                }
                ownership {
                    ...ownershipFields
                }
                ...domainEntitiesFields
            }
        }
    }
    ${ParentDomainsFieldsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${DomainEntitiesFieldsFragmentDoc}
`;

/**
 * __useListDomainsQuery__
 *
 * To run a query within a React component, call `useListDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDomainsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListDomainsQuery(baseOptions: Apollo.QueryHookOptions<ListDomainsQuery, ListDomainsQueryVariables>) {
    return Apollo.useQuery<ListDomainsQuery, ListDomainsQueryVariables>(ListDomainsDocument, baseOptions);
}
export function useListDomainsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListDomainsQuery, ListDomainsQueryVariables>,
) {
    return Apollo.useLazyQuery<ListDomainsQuery, ListDomainsQueryVariables>(ListDomainsDocument, baseOptions);
}
export type ListDomainsQueryHookResult = ReturnType<typeof useListDomainsQuery>;
export type ListDomainsLazyQueryHookResult = ReturnType<typeof useListDomainsLazyQuery>;
export type ListDomainsQueryResult = Apollo.QueryResult<ListDomainsQuery, ListDomainsQueryVariables>;
export const GetDomainChildrenCountDocument = gql`
    query getDomainChildrenCount($urn: String!) {
        domain(urn: $urn) {
            urn
            type
            children: relationships(input: { types: ["IsPartOf"], direction: INCOMING, start: 0, count: 0 }) {
                total
            }
        }
    }
`;

/**
 * __useGetDomainChildrenCountQuery__
 *
 * To run a query within a React component, call `useGetDomainChildrenCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainChildrenCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainChildrenCountQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDomainChildrenCountQuery(
    baseOptions: Apollo.QueryHookOptions<GetDomainChildrenCountQuery, GetDomainChildrenCountQueryVariables>,
) {
    return Apollo.useQuery<GetDomainChildrenCountQuery, GetDomainChildrenCountQueryVariables>(
        GetDomainChildrenCountDocument,
        baseOptions,
    );
}
export function useGetDomainChildrenCountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDomainChildrenCountQuery, GetDomainChildrenCountQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDomainChildrenCountQuery, GetDomainChildrenCountQueryVariables>(
        GetDomainChildrenCountDocument,
        baseOptions,
    );
}
export type GetDomainChildrenCountQueryHookResult = ReturnType<typeof useGetDomainChildrenCountQuery>;
export type GetDomainChildrenCountLazyQueryHookResult = ReturnType<typeof useGetDomainChildrenCountLazyQuery>;
export type GetDomainChildrenCountQueryResult = Apollo.QueryResult<
    GetDomainChildrenCountQuery,
    GetDomainChildrenCountQueryVariables
>;
export const CreateDomainDocument = gql`
    mutation createDomain($input: CreateDomainInput!) {
        createDomain(input: $input)
    }
`;
export type CreateDomainMutationFn = Apollo.MutationFunction<CreateDomainMutation, CreateDomainMutationVariables>;

/**
 * __useCreateDomainMutation__
 *
 * To run a mutation, you first call `useCreateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainMutation, { data, loading, error }] = useCreateDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDomainMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateDomainMutation, CreateDomainMutationVariables>,
) {
    return Apollo.useMutation<CreateDomainMutation, CreateDomainMutationVariables>(CreateDomainDocument, baseOptions);
}
export type CreateDomainMutationHookResult = ReturnType<typeof useCreateDomainMutation>;
export type CreateDomainMutationResult = Apollo.MutationResult<CreateDomainMutation>;
export type CreateDomainMutationOptions = Apollo.BaseMutationOptions<
    CreateDomainMutation,
    CreateDomainMutationVariables
>;
export const MoveDomainDocument = gql`
    mutation moveDomain($input: MoveDomainInput!) {
        moveDomain(input: $input)
    }
`;
export type MoveDomainMutationFn = Apollo.MutationFunction<MoveDomainMutation, MoveDomainMutationVariables>;

/**
 * __useMoveDomainMutation__
 *
 * To run a mutation, you first call `useMoveDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDomainMutation, { data, loading, error }] = useMoveDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveDomainMutation(
    baseOptions?: Apollo.MutationHookOptions<MoveDomainMutation, MoveDomainMutationVariables>,
) {
    return Apollo.useMutation<MoveDomainMutation, MoveDomainMutationVariables>(MoveDomainDocument, baseOptions);
}
export type MoveDomainMutationHookResult = ReturnType<typeof useMoveDomainMutation>;
export type MoveDomainMutationResult = Apollo.MutationResult<MoveDomainMutation>;
export type MoveDomainMutationOptions = Apollo.BaseMutationOptions<MoveDomainMutation, MoveDomainMutationVariables>;
export const DeleteDomainDocument = gql`
    mutation deleteDomain($urn: String!) {
        deleteDomain(urn: $urn)
    }
`;
export type DeleteDomainMutationFn = Apollo.MutationFunction<DeleteDomainMutation, DeleteDomainMutationVariables>;

/**
 * __useDeleteDomainMutation__
 *
 * To run a mutation, you first call `useDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainMutation, { data, loading, error }] = useDeleteDomainMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useDeleteDomainMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteDomainMutation, DeleteDomainMutationVariables>,
) {
    return Apollo.useMutation<DeleteDomainMutation, DeleteDomainMutationVariables>(DeleteDomainDocument, baseOptions);
}
export type DeleteDomainMutationHookResult = ReturnType<typeof useDeleteDomainMutation>;
export type DeleteDomainMutationResult = Apollo.MutationResult<DeleteDomainMutation>;
export type DeleteDomainMutationOptions = Apollo.BaseMutationOptions<
    DeleteDomainMutation,
    DeleteDomainMutationVariables
>;
export const UpdateDomainPropertyDocument = gql`
    mutation updateDomainProperty($input: UpdateDomainPropertyInfo!) {
        updateDomainProperty(input: $input)
    }
`;
export type UpdateDomainPropertyMutationFn = Apollo.MutationFunction<
    UpdateDomainPropertyMutation,
    UpdateDomainPropertyMutationVariables
>;

/**
 * __useUpdateDomainPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateDomainPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainPropertyMutation, { data, loading, error }] = useUpdateDomainPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDomainPropertyMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDomainPropertyMutation, UpdateDomainPropertyMutationVariables>,
) {
    return Apollo.useMutation<UpdateDomainPropertyMutation, UpdateDomainPropertyMutationVariables>(
        UpdateDomainPropertyDocument,
        baseOptions,
    );
}
export type UpdateDomainPropertyMutationHookResult = ReturnType<typeof useUpdateDomainPropertyMutation>;
export type UpdateDomainPropertyMutationResult = Apollo.MutationResult<UpdateDomainPropertyMutation>;
export type UpdateDomainPropertyMutationOptions = Apollo.BaseMutationOptions<
    UpdateDomainPropertyMutation,
    UpdateDomainPropertyMutationVariables
>;
export const DeleteDomainPropertyDocument = gql`
    mutation deleteDomainProperty($input: DeleteDomainPropertyInfo!) {
        deleteDomainProperty(input: $input)
    }
`;
export type DeleteDomainPropertyMutationFn = Apollo.MutationFunction<
    DeleteDomainPropertyMutation,
    DeleteDomainPropertyMutationVariables
>;

/**
 * __useDeleteDomainPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteDomainPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainPropertyMutation, { data, loading, error }] = useDeleteDomainPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDomainPropertyMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteDomainPropertyMutation, DeleteDomainPropertyMutationVariables>,
) {
    return Apollo.useMutation<DeleteDomainPropertyMutation, DeleteDomainPropertyMutationVariables>(
        DeleteDomainPropertyDocument,
        baseOptions,
    );
}
export type DeleteDomainPropertyMutationHookResult = ReturnType<typeof useDeleteDomainPropertyMutation>;
export type DeleteDomainPropertyMutationResult = Apollo.MutationResult<DeleteDomainPropertyMutation>;
export type DeleteDomainPropertyMutationOptions = Apollo.BaseMutationOptions<
    DeleteDomainPropertyMutation,
    DeleteDomainPropertyMutationVariables
>;
