import React, { useState } from 'react';
import { Button, Modal, message, Form, Switch, Typography, Select, Input, Row, Space, Tooltip } from 'antd';
import { LinkOutlined, FileFilled, InfoCircleOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import axios from 'axios';

import { OwnersAutocomplete } from '../containers/profile/sidebar/Ownership/OwnersAutocomplete';
import VerificationStatus from './VerificationStatus';

import { GLOSSARY_CSV_TEMPLATE, GLOSSARY_EXCEL_TEMPLATE, CSV, EXCEL } from '../constants';
import { generateSampleFile, validateHeadersXlsx } from './utils';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { EntityType } from '../../../../types.generated';
import utilService from '../../../../utils/util.service';
import ErrorLogModal from './ErrorLogModal';

interface Props {
    entityType: EntityType;
    entityData?: any;
    onClose: () => void;
}

// constants for file upload
const VERIFIED = 'VERIFIED';
const FAILED = 'FAILED';
const UPLOAD = 'UPLOAD';

function BulkLoadEntityModal(props: Props) {
    const [form] = Form.useForm();
    const { entityType, onClose, entityData = null } = props;
    const entityRegistry = useEntityRegistry();

    const [selectedOwners, setSelectedOwners]: any = useState([]);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // The following states are for file upload
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [status, setStatus] = useState(UPLOAD);

    const [errorLogs, setErrorLogs]: any = useState();
    const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState<boolean>();

    // Test Header for csv file
    const [expectedHeaders] = useState([
        'name',
        'description',
        'ownersUsers',
        'ownersGroups',
        'termSource',
        'sourceRef',
        'sourceUrl',
        'inherits',
        'contains',
        'customProperties',
        'knowledgeLinks',
        'domain',
    ]);

    function onBulkImport(resData) {
        const formData = new FormData();
        const ownerUrns = selectedOwners.map((owner: any) => owner.value.ownerUrn);

        const isXlsx = selectedFile?.name.endsWith('.xlsx');

        const { urn, properties, parentNodes } = entityData || {};
        Object.keys(resData).forEach((key: string) => {
            formData.append(key, resData[key]);
        });

        formData.append('file', selectedFile || '');
        formData.append('ownersUsers', ownerUrns.join(', '));
        formData.append('ownersGroups', '');

        if (!isXlsx) {
            formData.append('currentNodeUrn', urn || '');
            formData.append('currentNodeName', properties?.name || '');
            formData.append('currentNodeDescription', properties?.description || '');
            formData.append('parentNodes', JSON.stringify(parentNodes) || '{}');
        }

        setIsLoading(true);

        const url = isXlsx ? 'bulkGlossaryUpdate' : 'bulkGlossaryUpload';

        axios
            .post(`/api/${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-EDC-XSRF-TOKEN': utilService.getXsrfToken(),
                },
            })
            .then((res: any) => {
                message.success(res?.data?.STATUS || 'Uploaded Successfully');
                onClose();
            })
            .catch((error) => {
                message.error(error?.response?.data?.STATUS || error?.message || 'Something went wrong');
            })
            .finally(() => setIsLoading(false));
    }

    /* This method allow us to validate the header of the csv files */
    const validateHeaders = (csvData, fileName) => {
        const isXlsx = fileName.endsWith('.xlsx');

        if (isXlsx) {
            const results: any = validateHeadersXlsx(csvData, GLOSSARY_EXCEL_TEMPLATE, true);

            if (results?.every((result) => result.isValid)) {
                setIsVerified(true);
                setStatus(VERIFIED);
            } else {
                setErrorLogs({
                    isValid: results?.every((result) => result.isValid),
                    xlsxLogs: results?.filter((result) => !result.isValid),
                });

                setStatus(FAILED);
            }

            return;
        }

        const lines = csvData.split('\n');
        const headerRow = lines[0].trim().split(',');

        if (JSON.stringify(headerRow) === JSON.stringify(expectedHeaders)) {
            // Headers are valid; process the data
            setIsVerified(true);
            setStatus(VERIFIED);
        } else {
            // Headers are not valid; inform the user
            setStatus(FAILED);

            const missingHeaders = expectedHeaders.filter((header) => !headerRow.includes(header));
            const unexpectedHeaders = headerRow.filter((header) => !expectedHeaders.includes(header));

            setErrorLogs({ missingHeaders, unexpectedHeaders });
        }
    };

    const handleFile = (file) => {
        const reader = new FileReader();
        const isXlsx = file?.name.endsWith('.xlsx');

        reader.onload = (e) => {
            const csvData = reader.result;

            if (isXlsx) {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                validateHeaders(workbook, file?.name);
            } else {
                validateHeaders(csvData, file?.name);
            }
        };

        if (isXlsx) {
            reader.readAsArrayBuffer(file); // Read as ArrayBuffer for XLSX
        } else {
            reader.readAsText(file); // Read as text for CSV
        }
    };

    /**
     * Handle the dropped files, including file upload and validation.
     * - If the file is csv the it starts reading the csv file and then calls the validateHeaders for validation
     * - If the file is not csv it will change the status to failed and show the failed info card
     * @param {File[]} file - An array of dropped files.
     */
    const handleDroppedFiles = (files) => {
        setSelectedFile(files[0]);

        if (files[0]) {
            if (files[0].name.endsWith('.csv') || files[0].name.endsWith('.xlsx')) {
                handleFile(files[0]);
            } else {
                setStatus(FAILED);
            }
        }
    };

    /**
     * Handle the drop event when files are dropped onto a drop zone.
     *
     * @param {DragEvent} e - The drop event object.
     */
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        const { files } = e.dataTransfer;

        if (files.length > 0) {
            // Handle the dropped files (e.g., upload them)
            handleDroppedFiles(files);
        }
    };

    /* This method selects the file and call the validateHeaders for validating the headers */
    const selectFile = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (file) {
            handleFile(file);
        } else {
            setStatus(FAILED);
        }
    };

    /* Resets the header and show upload card */
    const removeSelectedFile = () => {
        setStatus(UPLOAD);
        setSelectedFile(null);
        setIsVerified(false);
    };

    /**
     * Render the upload card based on the status.
     * - If status is VERIFIED then it will show the veried card information
     * - If status is FAILED then it will show the failed card information
     * - If status is UPLOAD then it will show the default upload card
     * @returns {JSX.Element} The JSX element representing the upload card.
     */
    const renderUploadCard = () => {
        switch (status) {
            case VERIFIED:
                return (
                    <>
                        <div className="f-container-title">Verification Successful</div>

                        <Row justify="space-around" align="middle">
                            <div>
                                <FileFilled style={{ color: '#1CA8DD' }} className="mr-2" />
                                {selectedFile?.name}
                            </div>
                            <Button type="text" onClick={removeSelectedFile}>
                                Remove
                            </Button>
                        </Row>
                    </>
                );
            case FAILED:
                return (
                    <VerificationStatus
                        selectedFile={selectedFile?.name || ''}
                        onRetry={removeSelectedFile}
                        setIsErrorLogModalVisible={setIsErrorLogModalVisible}
                    />
                );
            default:
                return (
                    <div onDrop={handleDrop}>
                        <div className="f-container-title">Drag & drop your csv or xlsx here</div>
                        <div className="f-container-subtitle mt-2">We will verify it against the template first</div>
                        <Button className="f-btn-sec ma-0 mt-2">OR select File to Import</Button>
                        <input type="file" accept=".csv, .xlsx" aria-label="CSV Bulk Import" onChange={selectFile} />
                    </div>
                );
        }
    };

    const downloadTemplate = () => {
        const { header, data, name } = GLOSSARY_CSV_TEMPLATE;

        // Create the CSV Sample Data
        const csvData = [[header.join(',')], [data.join(',')]];

        // Create a Blob containing the CSV data
        const blob = new Blob([csvData.join('\n')], { type: 'text/csv' });

        generateSampleFile(name, blob);
    };

    const createExcelWithMultipleSheets = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Loop through each sheet data and add to workbook dynamically
        GLOSSARY_EXCEL_TEMPLATE.forEach(({ sheetName, sheetData }) => {
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData); // Create sheet from data
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); // Append sheet to workbook
        });

        // Generate Excel file and trigger download
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        generateSampleFile('glossary.xlsx', blob);
    };

    // Handle the download sample based on the format
    const handleSampleDownload = (format) => {
        switch (format) {
            case CSV:
                downloadTemplate();
                break;
            case EXCEL:
                createExcelWithMultipleSheets();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Modal
                open
                title={`Bulk Import ${entityRegistry.getEntityName(entityType)}`}
                width={780}
                centered
                closable={false}
                onCancel={onClose}
                footer={
                    <>
                        <Button
                            form="importBulkForm"
                            type="primary"
                            htmlType="submit"
                            disabled={isFormValid && !isVerified}
                            loading={isLoading}
                        >
                            Import
                        </Button>

                        <Button onClick={onClose} type="default">
                            Cancel
                        </Button>
                    </>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    name="importBulkForm"
                    onFinish={onBulkImport}
                    initialValues={{ enableAutoId: false, version: 1, url: '' }}
                    onFieldsChange={() =>
                        setIsFormValid(form.getFieldsError().some((field) => field.errors.length > 0))
                    }
                >
                    {/* File Upload */}
                    <Form.Item>
                        <div className={`f-file-upload-container ${isDragging ? 'dragging' : ''}`}>
                            {renderUploadCard()}
                        </div>
                    </Form.Item>

                    {isVerified && (
                        <>
                            <Form.Item
                                className="mb-0"
                                label="Enable Auto ID"
                                valuePropName="checked"
                                name="enableAutoId"
                            >
                                <Switch />
                            </Form.Item>
                            <Typography.Paragraph type="secondary">
                                It is recommended to set this option to true. This will enable EDC to automatically
                                generate GUIDs from the provided term names.
                            </Typography.Paragraph>
                            <Form.Item label="Version" name="version">
                                <Select disabled>
                                    <Select.Option value={1}>1</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Users"
                                rules={[
                                    {
                                        required: selectedOwners.length < 1,
                                        message: 'atleast one owner must be selected',
                                    },
                                ]}
                            >
                                <OwnersAutocomplete
                                    selectedOwners={selectedOwners}
                                    setSelectedOwners={setSelectedOwners}
                                />
                            </Form.Item>
                            <Form.Item
                                label="External URL"
                                name="externalUrl"
                                className="mb-0"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                        message: 'This field must be a valid url.',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter URL" autoFocus prefix={<LinkOutlined />} />
                            </Form.Item>
                            <Typography.Paragraph type="secondary">
                                If applicable, please provide URL that links to an external source where the glossary is
                                defined.
                            </Typography.Paragraph>
                        </>
                    )}

                    {/* Download Template */}
                    <div className="mb-2">
                        <Space align="center">
                            <Button type="link" onClick={() => handleSampleDownload(CSV)}>
                                Download CSV Template
                            </Button>
                            <Tooltip
                                placement="top"
                                title="Import the CSV file following the provided template. Ensure that column placement aligns with the template for accurate data processing."
                                showArrow
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                        <Space align="center" className="ml-2">
                            <Button type="link" onClick={() => handleSampleDownload(EXCEL)}>
                                Download Sample Excel
                            </Button>
                            <Tooltip
                                placement="top"
                                title="Import the xlsx file following the provided template. Ensure that column placement aligns with the template for accurate data processing."
                                showArrow
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    </div>
                </Form>
            </Modal>
            {isErrorLogModalVisible && (
                <ErrorLogModal onClose={() => setIsErrorLogModalVisible(false)} errorLogs={errorLogs} />
            )}
        </>
    );
}

export default BulkLoadEntityModal;
