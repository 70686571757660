import React from 'react';
import styled from 'styled-components';

const HeaderInfoItem = styled.div<{ onClick?: () => void; width?: string }>`
    display: inline-block;
    text-align: left;
    width: ${(props) => (props.width ? `${props.width};` : 'auto;')};
    vertical-align: top;
    &:hover {
        cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
    }
    &:not(:first-child) {
        margin-top: 8px;
    }
`;

type Props = {
    title: string;
    children: React.ReactNode;
    onClick?: () => void;
    width?: string;
};

export const InfoItem = ({ title, children, width, onClick }: Props) => {
    return (
        <HeaderInfoItem className="f-left-border-highlight py-0 pl-3" onClick={onClick} width={width}>
            <div className="f-text-content f-color-dark-black-s50">{title}</div>
            <span>{children}</span>
        </HeaderInfoItem>
    );
};
