import { Menu, Row, Col, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useEntityData } from '../../shared/EntityContext';
import GlossaryRelatedTermsResult, { RelatedTermTypes } from './GlossaryRelatedTermsResult';

export default function GlossayRelatedTerms() {
    const { entityData } = useEntityData();
    const [selectedKey, setSelectedKey] = useState('');
    const menuOptionsArray = Object.keys(RelatedTermTypes);

    useEffect(() => {
        if (menuOptionsArray && menuOptionsArray.length > 0 && selectedKey.length === 0) {
            setSelectedKey(menuOptionsArray[0]);
        }
    }, [menuOptionsArray, selectedKey]);

    const onMenuClick = ({ key }) => {
        setSelectedKey(key);
    };

    return (
        <Row>
            <Col xs={24} lg={4} className="pa-1">
                <Card style={{ height: '100%' }}>
                    <Menu
                        selectable={false}
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        onClick={(key) => {
                            onMenuClick(key);
                        }}
                    >
                        {menuOptionsArray.map((option) => (
                            <Menu.Item data-testid={option} key={option} tabIndex={0}>
                                {RelatedTermTypes[option]}
                            </Menu.Item>
                        ))}
                    </Menu>
                </Card>
            </Col>
            <Col xs={24} lg={20} className="pl-4 f-pl-1-xs">
                {selectedKey && entityData && (
                    <GlossaryRelatedTermsResult
                        glossaryRelatedTermType={RelatedTermTypes[selectedKey]}
                        glossaryRelatedTermResult={
                            entityData[selectedKey]?.relationships || entityData[selectedKey]?.nodes || []
                        }
                    />
                )}
            </Col>
        </Row>
    );
}
